<template>
  <v-card flat class="pa-3">
    <v-card-title>
      <v-icon size="26" class="text--primary me-3">
        {{ icons.mdiChartTimelineVariant }}
      </v-icon>
      <span>Activity</span>
    </v-card-title>

    <!-- Activity -->
    <v-card-text>
      <v-switch
        v-model="settings.notify_keyword_research"
        hide-details
        class="mt-0"
        @change="saveSettingsData()"
      >
        <template #label>
          <span class="text-sm ms-2">Email me when keyword research report is completed</span>
        </template>
      </v-switch>

      <v-switch
        v-model="settings.notify_semantic_report"
        hide-details
        class="mt-3"
        @change="saveSettingsData()"
      >
        <template #label>
          <span class="text-sm ms-2">Email me when semantic analysis report is completed</span>
        </template>
      </v-switch>

      <v-switch
        v-model="settings.notify_serp_report"
        hide-details
        class="mt-3"
        @change="saveSettingsData()"
      >
        <template #label>
          <span class="text-sm ms-2">Email me when SERP analysis report is completed</span>
        </template>
      </v-switch>

      <v-switch
        v-model="settings.notify_full_report"
        hide-details
        class="mt-3"
        @change="saveSettingsData()"
      >
        <template #label>
          <span class="text-sm ms-2">Email me when Full report is completed</span>
        </template>
      </v-switch>

    </v-card-text>

    <v-card-title class="mt-2">
      <v-icon size="26" class="text--primary me-3">
        {{ icons.mdiEmailOutline }}
      </v-icon>
      <span>Application</span>
    </v-card-title>

    <!-- Application -->
    <v-card-text>
      <v-switch v-model="settings.dark_theme" hide-details class="mt-3">
        <template #label>
          <span class="text-sm ms-2">Dark Theme</span>
        </template>
      </v-switch>
    </v-card-text>

    <!-- <v-card-text>
      <v-btn color="primary" class="me-3 mt-5" @click="saveSettingsData"> Save changes </v-btn>
      <v-btn color="secondary" outlined class="mt-5"> Cancel </v-btn>
    </v-card-text> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {},
  data() {
    return {
      loading: false,
      settings: {
        dark_theme: false,
        notify_keyword_research: false,
        notify_semantic_report: false,
        notify_serp_report: false,
        notify_full_report: false,
      },
      icons: { mdiChartTimelineVariant, mdiEmailOutline },
    }
  },
  mounted() {
    this.getSettingsData()
  },
  methods: {
    ...mapActions({
      getSettings: 'settings/getSettings',
      saveSettings: 'settings/saveSettings',
    }),
    async getSettingsData() {
      await this.getSettings()
        .then(resp => {
          this.settings = resp.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    async saveSettingsData() {
      await this.saveSettings(this.settings)
        .then( resp => {
          console.log(this.resp);
          // await this.getSettingsData();
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
