<template>
  <div>
    <v-card flat class="py-4">
      <v-form>
        <div class="px-3">
          <v-card-text class="pt-5">
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <!-- current password -->
                <v-text-field
                  v-model="current_password"
                  :type="isCurrentPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  label="Current Password"
                  outlined
                  dense
                  @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                ></v-text-field>

                <!-- new password -->
                <v-text-field
                  v-model="new_password"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  label="New Password"
                  outlined
                  dense
                  hint="Make sure it's at least 8 characters."
                  persistent-hint
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>

                <!-- confirm password -->
                <v-text-field
                  v-model="current_password"
                  :type="isCPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  label="Confirm New Password"
                  outlined
                  dense
                  class="mt-3"
                  @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <!-- divider -->
        <v-divider></v-divider>

        <div class="pa-3">
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-3">
              {{ icons.mdiKeyOutline }}
            </v-icon>
            <span class="text-break">Two-factor authentication</span>
          </v-card-title>

          <v-card-text class="two-factor-auth text-center mx-auto">
            <v-avatar color="primary" class="v-avatar-light-bg primary--text mb-4" rounded>
              <v-icon size="25" color="primary">
                {{ icons.mdiLockOpenOutline }}
              </v-icon>
            </v-avatar>
            <p class="text-base text--primary font-weight-semibold">Two factor authentication is not enabled yet.</p>
            <p class="text-sm text--primary">
              Two-factor authentication adds an additional layer of security to your account by requiring more than just
              a password to log in.
            </p>
          </v-card-text>

          <!-- action buttons -->
          <v-card-text>
            <v-btn color="primary" class="me-3 mt-3"> Save changes </v-btn>
            <v-btn color="secondary" outlined class="mt-3"> Cancel </v-btn>
          </v-card-text>
        </div>

        <!-- divider -->
        <v-divider></v-divider>

    <!-- recent divices -->

        <div class="pa-3">
          <v-card-title> Recent devices </v-card-title>

      <v-data-table :headers="tableColumnHeaders" :items="recenntDevices" hide-default-footer class="text-no-wrap" mobile-breakpoint="0">
        <template #[`item.browser`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="22" class="me-2">
              <v-img :src="require(`@/assets/images/logos/${item.browser}`)"></v-img>
            </v-avatar>
            <span class="text--primary text-no-wrap font-weight-medium">{{ item.name }}</span>
          </div>
        </template>
      </v-data-table>

        </div>

      </v-form>
    </v-card>


  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  data() {
    return {
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      current_password: '12345678',
      new_password: '87654321',
      current_password: '87654321',
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      tableColumnHeaders: [
        { text: 'BROWSER', value: 'browser', sortable: false },
        { text: 'DEVICE', value: 'device', sortable: false },
        { text: 'LOCATION', value: 'location', sortable: false },
        { text: 'RECENT ACTIVITY', value: 'recentActivity', sortable: false },
      ],
      recenntDevices: [
        {
          browser: 'chrome.png',
          name: 'Chrome on Windows',
          device: 'Dell XPS 15',
          location: 'United States',
          recentActivity: '10, Jan 2020 20:07',
        },
        {
          browser: 'chrome.png',
          name: 'Chrome on Android',
          device: 'Google Pixel 3a',
          location: 'Ghana',
          recentActivity: '11, Jan 2020 10:16',
        },
        {
          browser: 'chrome.png',
          name: 'Chrome on MacOS',
          device: 'Apple iMac',
          location: 'Mayotte',
          recentActivity: '11, Jan 2020 12:10',
        },
        {
          browser: 'chrome.png',
          name: 'Chrome on IPhone',
          device: 'Apple iPhone XR',
          location: 'Mauritania',
          recentActivity: '12, Jan 2020 8:29',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
