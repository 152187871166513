<template>
  <v-card
    flat
    class="pa-3"
  >
    <v-card-text class="d-flex">
      <v-avatar
      rounded
      size="100"
      class="me-6"
      >
        <div
          class="previewBlock"
          @click="chooseFile"
          v-if="filePreview"
          >
           <v-img :src="filePreview" alt="" max-height="100px" max-width="100px"></v-img>
        </div>
        <div
          class="previewBlock"
          @click="chooseFile"
          v-else-if="!filePreview">
          <img src="@/assets/images/avatars/1.png" alt="">
        </div>
      </v-avatar>

        <div>
          <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="chooseFile"
        >
        Upload file
        </v-btn>
          <input
            class="form-control form-control-lg input-file"
            ref="fileInput"
            type="file"
            id="formFileLg"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @input="selectImgFile">
        </div>
      <!-- <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="file"></v-img>
      </v-avatar>

      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            mdiCloudUploadOutline
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          v-on:change="onChange"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div> -->
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>

          <v-col
            md="6"
            cols="12"
          >
          <label for="">Email</label>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{user.email}}
              </v-chip>
          </v-col>
          <v-col></v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.first_name"
              label="First Name"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.last_name"
              label="Last Name"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.address"
              label="Address"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.phone"
              label="Phone"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.city"
              label="City"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.country"
              label="Country"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="profile.zip"
              label="Zip"
              dense
              outlined
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>

          <v-col v-if="canEdit" cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="postFile"
            >
              Save changes
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="canEdit = false"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col v-if="!canEdit" cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="canEdit = true"
            >
              Edit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@axios'

export default {
  data() {
    return {
      profile: [],
      account: [],
      roles: [],
      user: [],
      itemsPerPage: [
        {
          id: 5,
          name: 5,
        },
        {
          id: 10,
          name: 10,
        },
        {
          id: 15,
          name: 15,
        },
        {
          id: 20,
          name: 20,
        },
        {
          id: 25,
          name: 25,
        },
        {
          id: 30,
          name: 30,
        },
      ],
      isLoaded: false,
      filePreview: null,
      file: '',
      canEdit: false,
    }
  },
  methods: {
    chooseFile() {
      this.$refs.fileInput.click()
      this.canEdit = true
    },
    selectImgFile(e) {
      let fileInput = this.$refs.fileInput
      this.file = fileInput.files[0]
      let imgFile = fileInput.files

      if (imgFile && imgFile[0]) {
        let reader = new FileReader()
        reader.onload = e => {
          this.filePreview = e.target.result
        }
        reader.readAsDataURL(imgFile[0])
        this.$emit('fileInput', imgFile[0])
      }
    },
    ...mapActions({
      getUser: 'auth/getUser',
    }),
    async getUserData() {
      await this.getUser().then(response => {
        this.profile = response.data.profile
        this.user = response.data
        this.roles = response.data.roles
        this.account = this.profile.account
        if (this.profile.avatar) {
          this.filePreview = process.env.VUE_APP_RESOURCE_URL + '/profile/' + this.profile.avatar
        } else {
          this.filePreview = ''
        }
        this.isLoaded = true
      })
    },
    sendData() {
      if (this.file) {
        this.postFile()
      } else {
        this.updateUser()
      }
    },
    postFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      const headers = { 'Content-Type': 'multipart/form-data' }
      axios
        .post('/user/avatar', formData, { headers })
        .then(response => {
          this.profile.avatar = response.data.success
          this.updateUser()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    updateUser() {
      axios
        .post('/user', { profile: this.profile })
        .then(response => {
          console.log('updated')
          console.log(response.data)
          localStorage.setItem('userProfile', JSON.stringify(response.data))
          this.canEdit = false
          location.reload()
          this.$alertify.success('Profile Updated')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          this.canEdit = false
          this.$alertify.error('Error Updating')
        })
    },
    setItemsPerPage(a) {
      localStorage.setItem('itemsPerPage', a)
      console.log(a)
    },
  },
  mounted() {
    this.getUserData()
  },
}
</script>

<style scoped lang="css">
.previewBlock {
  display: block;
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  background-position: center center;
  background-size: cover;
}
</style>
